import  useAuth  from './AuthContext';
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { Messages } from './Messages';
import { ChatMessage, sendChatMessage, getChatMessages, probeToken, listModels, checkRunningJobs, createNewChat, getAllChats, ChatWithTitle } from './API';
import { Sidebar, SidebarButton } from './Sidebar';
import Popup from './PopUp';
import { statePopUpWindow, useAppContext } from './AppContext';
import { Settings } from './Settings';
import { Workers } from './Workers';


interface OutgoingMessage
{
    text: string;
    image: string;
}

function MainChat() 
{
    const [textAreaData, setTextAreaData] = useState<string>('');
    const [inOverlay, setInOverlay] = useState<boolean>(false);
    const [attachedFile, setAttachedFile] = useState<string>();

    const { state, setPopUpState, setChatBlocked, setAvailableModels, setTextModel, setVisionModel, setActiveChatID, setActiveChatHistory, setRunningJobID, setChatList } = useAppContext();

    const { keycloak } = useAuth();

    const chatBoxRef = createRef<HTMLDivElement>();
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const createChatAndSendMessage = async () =>
    {
      if(!keycloak.token || !keycloak.tokenParsed || keycloak.tokenParsed.sub === undefined)
        return;
      const oldLength = state.chatList.length;
      await createNewChat(keycloak.token);
      let res = await getAllChats(keycloak.token);
      while(!res || res.length == oldLength)
      {
        res = await getAllChats(keycloak.token);
        await new Promise(resolve => setTimeout(resolve, 100)); // Timeout before next attempt
      }
      if(res)
      {
        setChatList(res);
        if(process.env.REACT_APP_LOGGING)
          console.log(res);
        const newChatID = res[res.length - 1]
        setActiveChatID(newChatID.chatID);
        sendMessageHandler(newChatID.chatID);
      }

    }

    const sendMessageHandler = async (chatID?: string) => 
    {
        try 
        {
            if(!keycloak.token || !keycloak.tokenParsed || keycloak.tokenParsed.sub === undefined)
              return;
            //const outgoingChatEntity: ChatMessage = { sender_id: keycloak.tokenParsed.sub, content: textAreaData.text, sent_at: '' };
            // setActiveChatHistory((activeChatHistory) => [...activeChatHistory, outgoingChatEntity]);                  
            setTextAreaData('');

            setActiveChatHistory({
              ...state.activeChatHistory,
              ["last"]:  {
                content: textAreaData,
                sender_id: keycloak.tokenParsed?.sub ? keycloak.tokenParsed.sub : '',
                sent_at: "",
                attachments: []
              }   
            });

            const image = attachedFile? attachedFile.replace(/^data:\w+\/\w+;base64,/, '') : '';
            setAttachedFile('');
            const jobID = await sendChatMessage(keycloak.token, image.length > 0 ? state.visionModel : state.textModel, chatID ? chatID : state.activeChatID, textAreaData, image);
            setChatBlocked(true);
            setRunningJobID(jobID);

            var res = await getChatMessages(keycloak.token, chatID ? chatID : state.activeChatID);
            if(res)
                setActiveChatHistory(res);  

        } 
        catch (error) 
        {
            console.error('Error sending message!', error);
            setChatBlocked(false);
        }
    }    

    const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => 
    {
        setTextAreaData(e.target.value);
        const textarea = textareaRef.current;
        if (textarea) {
          const prevScrollTop = textarea.scrollTop;
          // Maintain scroll position
          requestAnimationFrame(() => {
            if (textarea) {
              textarea.scrollTop = prevScrollTop;
            }
          });
        }
    }

    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => 
    {
        if (e.key === 'Enter' && !e.shiftKey) 
        {
            e.preventDefault();
            e.currentTarget.name == "newChatText" ? createChatAndSendMessage() : sendMessageHandler();
        }
    }

    const handleDrop = (e: React.DragEvent<HTMLTextAreaElement>) => 
    {
      if(state.activeChatID.length < 1)
        return; 

      e.preventDefault();
      e.stopPropagation();
  
      const file = e.dataTransfer.files[0];
      const reader = new FileReader();
  
      reader.onloadend = () => {     
        setAttachedFile(reader.result?.toString());
      };
  
      if (file) {
        reader.readAsDataURL(file);
      }
    };

    const updateTextArea = () =>
    {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        if(textareaRef.current.scrollHeight < 400)
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        else
          textareaRef.current.style.height = `400px`;
      }
    }

    useEffect(() => {
      updateTextArea();
    }, [textAreaData]);

    useEffect(() => {
      updateTextArea();
    }, [textareaRef.current?.scrollHeight]);
  
    useEffect(() => {
      textareaRef.current?.focus();
    }, [state.chatBlocked]);
    
    useEffect(() => 
    {
      (async function() {     
        setAttachedFile('');
        setActiveChatHistory({});
        setTextAreaData('');
        updateTextArea();

        textareaRef.current?.focus();
  
        if(!keycloak.token)
          return; 

        if(state.activeChatID.length < 1)
          return;
        
        let res = await getChatMessages(keycloak.token, state.activeChatID);
        if(res)
            setActiveChatHistory(res);
      })();
    }, [state.activeChatID]);

    useEffect(() => 
      {
        (async function() {
          updateTextArea();

          if(!keycloak.token)
            return;

          await probeToken(keycloak.token);

          const models = await listModels(keycloak.token);
          if(models)
          {
            setAvailableModels(models);
            if(models.text && models.text.length > 0)
            {
              const storedModel = localStorage.getItem("txt-mdl");
              if(storedModel && models.text.includes(storedModel))
              {
                setTextModel(storedModel);
                if(process.env.REACT_APP_LOGGING)
                  console.log("using model from storage", storedModel);
              }
              else
              {
                setTextModel(models.text[0]);
                if(process.env.REACT_APP_LOGGING)
                  console.log("set model to", models.text[0]);
              }
            }
            if(models.vision && models.vision.length > 0)
            {
              const storedModel = localStorage.getItem("vis-mdl");
              if(storedModel && models.vision.includes(storedModel))
              {
                setVisionModel(storedModel);
                if(process.env.REACT_APP_LOGGING)
                  console.log("using vision model from storage", storedModel);
              }
              else
              {
                setVisionModel(models.vision[0]);
                if(process.env.REACT_APP_LOGGING)
                  console.log("set vision model to", models.vision[0]);
              }
            }
          }
          
        })();
      }, []);

      const insertCodeBlock = () => {
        if (textareaRef.current) {
          if(process.env.REACT_APP_LOGGING)
            console.log("code block")
          const start = textareaRef.current.selectionStart;
          const end = textareaRef.current.selectionEnd;
          const newValue = textAreaData.substring(0, start) + '\n```\n\n```\n' + textAreaData.substring(end);
          setTextAreaData(newValue);
          
          // Set cursor position
          setTimeout(() => {
            if (textareaRef.current) {
              textareaRef.current.selectionStart = start + 5;
              textareaRef.current.selectionEnd = start + 5;
              textareaRef.current.focus();
            }
          }, 0);
        }
      };


    return (
<div>
<Sidebar activeChatID={state.activeChatID} />
{state.activeChatID && (<div className="relative h-screen w-full lg:ps-64">
  <div className="py-10 lg:py-14" >
    <div className="max-w-4xl px-4 sm:px-6 lg:px-8 mx-auto text-center">
      <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl dark:text-white">
        beep ai
      </h1>
      <br />
    </div>

    <div className="flex flex-col"> 
        <Messages messageHistory={state.activeChatHistory} />   
    </div>
  </div>

  <div className="max-w-4xl sticky mx-auto bottom-0 z-10 p-3" >
   <SidebarButton inOverlay={inOverlay} setInOverlay={setInOverlay} />





  <div className="relative" ref={chatBoxRef}>

  {attachedFile &&   <div className="bg-white border border-gray-200 rounded-2xl p-4 space-y-3 dark:bg-neutral-900 dark:border-neutral-700">
    <h2 className="font-medium text-gray-800 dark:text-white">
    Attached
    </h2>
    <div className="grid grid-cols-6 gap-4">
      <div className="...">
        <img src={attachedFile}/>
      </div>
      <div>
      <button type="button" onClick={()=>{setAttachedFile('')}} className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-red-100 text-red-800 hover:bg-red-200 focus:outline-none focus:bg-red-200 disabled:opacity-50 disabled:pointer-events-none dark:text-red-500 dark:bg-red-800/30 dark:hover:bg-red-800/20 dark:focus:bg-red-800/20">
          Remove
        </button>

      </div>
    </div>
  </div>
}
  <br />  
      <textarea ref={textareaRef} onDrop={handleDrop} value={textAreaData} name="text" onChange={onChangeHandler} onKeyDown={onKeyDownHandler}
        className="resize-none p-4 pb-12 block w-full bg-gray-100 border-gray-200 rounded-lg text-base dark:placeholder:text-neutral-400 focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-800 dark:border-neutral-100 dark:text-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" placeholder="Ask me anything..." disabled={state.chatBlocked}></textarea>
      <div className="absolute bottom-px inset-x-px p-2 rounded-b-lg bg-gray-100 dark:bg-neutral-800">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <button onClick={(e)=>{insertCodeBlock();e.preventDefault();}} title='Insert code block' type="button" className="inline-flex shrink-0 justify-center items-center size-8 rounded-lg text-gray-500 hover:bg-white focus:z-10 focus:outline-none focus:bg-white dark:text-neutral-500 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
              <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect width="18" height="18" x="3" y="3" rx="2"/><line x1="9" x2="15" y1="15" y2="9"/></svg>
            </button>

            <button type="button" className="inline-flex shrink-0 justify-center items-center size-8 rounded-lg text-gray-500 hover:bg-white focus:z-10 focus:outline-none focus:bg-white dark:text-neutral-500 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
              <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48"/></svg>
            </button>

            <button onClick={(e)=>{setPopUpState(statePopUpWindow.Settings);e.preventDefault();}} type="button" className="justify-center items-center rounded-lg text-gray-500 hover:bg-white focus:z-10 focus:outline-none focus:bg-white dark:text-neutral-500 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
              <p className='items-center px-2 py-1 gap-x-2 h-24px text-base font-normal'>{state.textModel}</p>
            </button>
          </div>

          <div className="flex items-center gap-x-1">
            <button type="button" className="inline-flex shrink-0 justify-center items-center size-8 rounded-lg text-gray-500 hover:bg-white focus:z-10 focus:outline-none focus:bg-white dark:text-neutral-500 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
              <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z"/><path d="M19 10v2a7 7 0 0 1-14 0v-2"/><line x1="12" x2="12" y1="19" y2="22"/></svg>
            </button>

            <button type="button" onClick={(e)=>{e.preventDefault(); sendMessageHandler();}} className="inline-flex shrink-0 justify-center items-center size-8 rounded-lg text-white bg-blue-600 hover:bg-blue-500 focus:z-10 focus:outline-none focus:bg-blue-500">
              <svg className="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>)}

{!state.activeChatID && (
  <div className="relative h-screen w-full lg:ps-64">
    <div className="w-full mx-auto p-6 py-10 lg:py-14">
      <div className="max-w-4xl px-4 sm:px-6 lg:px-8 mx-auto text-center">
      <div>        <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl dark:text-white">
          BEEP AI
        </h1>
        <p className="inline-block mt-3 text-gray-600 dark:text-neutral-400 ">
        UI powered by <a className=" inline-block text-blue-600 decoration-gray-800 hover:opacity-80 focus:outline-none focus:opacity-80 dark:decoration-white" href="https://preline.co/">preline.co</a>
        </p>
</div>
      </div>
      <div className="mt-10 max-w-2xl w-full mx-auto">
      <div className="relative">
        <textarea ref={textareaRef} onDrop={handleDrop} value={textAreaData} name="newChatText" onChange={onChangeHandler} onKeyDown={onKeyDownHandler} className="resize-none p-4 block w-full border-gray-200 rounded-lg text-base focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:placeholder:text-neutral-400 focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-800 dark:border-neutral-100 dark:text-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" placeholder="How can I help you?" />
        <div className="absolute top-1/2 end-2 -translate-y-1/2">
          <button type="button" className="size-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-500 hover:text-gray-800 focus:outline-none focus:text-gray-800 bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:bg-neutral-800 dark:hover:text-white dark:focus:text-white">
            <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z"/><path d="M19 10v2a7 7 0 0 1-14 0v-2"/><line x1="12" x2="12" y1="19" y2="22"/></svg>
          </button>
          <button type="button" onClick={(e)=>{e.preventDefault(); createChatAndSendMessage();}} className="size-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-500 hover:text-gray-800 focus:outline-none focus:text-gray-800 bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:bg-neutral-800 dark:hover:text-white dark:focus:text-white">
            <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
            </svg>
          </button>

        </div>
      </div>
    </div>
    <div className='mt-5 max-w-2xl mx-auto px-4 sm:px-6 lg:px-8'>
      <div>
      <svg className="inline-block align-middle shrink-0 size-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
      <path d="M20 12C20 16.4183 16.4183 20 12 20C10.5937 20 9.27223 19.6372 8.12398 19C7.53267 18.6719 4.48731 20.4615 3.99998 20C3.44096 19.4706 5.4583 16.6708 5.07024 16C4.38956 14.8233 3.99999 13.4571 3.99999 12C3.99999 7.58172 7.58171 4 12 4C16.4183 4 20 7.58172 20 12Z" stroke="currentColor" stroke-linejoin="round"/>
    </svg>
      <p className="inline-block align-middle ml-1 text-gray-600 dark:text-neutral-400">Recent chats:</p>

      </div>
      <div className="mt-5 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3">
          {state.chatList.length > 0 && state.chatList.map((entity, index) => (
            <div className='py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-500 text-gray-500 hover:border-gray-800 hover:text-gray-800 focus:outline-none focus:border-gray-800 focus:text-gray-800 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-400 dark:text-neutral-400 dark:hover:text-neutral-300 dark:hover:border-neutral-300' key={index} onClick={(e)=>{setActiveChatID(entity.chatID); e.preventDefault()}}>
              <p className="overflow-hidden whitespace-nowrap align-middle text-gray-600 dark:text-neutral-400">{(entity && entity.title && entity.title.length > 0) ? entity.title : 'Unnamed chat'}</p>
            </div>
          ))}
      </div>
    </div>
      <SidebarButton inOverlay={inOverlay} setInOverlay={setInOverlay} />
    </div>
  </div>
)}
{state.popUpState == statePopUpWindow.Settings && ( <Popup windowName='Settings' onClose={()=>{setPopUpState(statePopUpWindow.Closed)}}><Settings/></Popup> ) }
{state.popUpState == statePopUpWindow.Workers && ( <Popup windowName='Workers' onClose={()=>{setPopUpState(statePopUpWindow.Closed)}}><Workers/></Popup> ) }
</div>
    );
}

export default MainChat