// import  MarkdownRenderer, { MemoMarkdownRenderer }  from "./FormattedText";
import  useAuth  from './AuthContext';
import { createRef, useEffect, useState } from "react";
import { Media } from "./Media";
import { ChatMessage, Attachment, getChatMessages, checkRunningJobs, getJobData, JobResponse, getAllChats } from "./API";
import { useAppContext } from './AppContext';
import { FormattedText, VisibilityChecker } from "./FormattedText";
import hljs from 'highlight.js';
import 'highlight.js/styles/vs2015.css';

type MessagesProps = 
{
    messageHistory: ChatMessage;
}

const LastMessage = () =>
{
  const { keycloak } = useAuth();
  const { state, setChatBlocked, setActiveChatHistory, setRunningJobID, setChatList } = useAppContext();
  const [cycleCounter, setCycleCounter] = useState<number>(1);
  const [streamingData, setStreamingData] = useState<string>('');

  const checkStatus = async (currentJobId: string) => {
    if(!keycloak.token)
      return; 

    try 
    {
      const jobData = await getJobData(keycloak.token, currentJobId);
      if (!(jobData.finished == 2) && state.chatBlocked) 
      {
        setStreamingData(jobData.data);
        await new Promise(resolve => setTimeout(resolve, 500)); // Timeout before next attempt
        const newID = cycleCounter > 3 ? cycleCounter - 1 : cycleCounter + 1;
        setCycleCounter(newID);
      } 
      else if ((jobData.finished == 2)) 
      {
        setChatBlocked(false);
        setRunningJobID('');

        const needsToReloadChats = Object.entries(state.activeChatHistory).length < 2

        const res = await getChatMessages(keycloak.token, state.activeChatID);
        if(res)
            setActiveChatHistory(res);
        
         
        if(needsToReloadChats)
        {
            if(process.env.REACT_APP_LOGGING)
              console.log("reloading chats for title");
            const res = await getAllChats(keycloak.token);
            if(res)
            {
              setChatList(res);
            }            
        }

        setStreamingData('');

      }
    } catch (err) {
      setChatBlocked(false);
      setRunningJobID('');
    }
  };


  useEffect(() => 
  {
    if (state.chatBlocked && state.runningJobID.length > 0) {
      checkStatus(state.runningJobID);
    }
  }, [state.chatBlocked, state.runningJobID, cycleCounter]);

  useEffect(() => 
  {
    (async function() {     
      setChatBlocked(false);

      if(!keycloak.token)
        return; 

      if(state.activeChatID.length < 1)
        return;

      const jobID = await checkRunningJobs(keycloak.token, state.activeChatID);
      setRunningJobID(jobID);
      setChatBlocked((jobID.length > 0));
    })();
  }, [state.activeChatID]);
  

  return (
    <div>
    { state.chatBlocked && streamingData.length < 1 &&
      (     
      <div className="flex items-start space-x-3 ">
        <img src="/bot.png" alt="User Avatar" className="w-10 h-10 rounded-full hidden md:block" />
        <div className="bg-gray-100 dark:bg-neutral-800 rounded-lg p-3 w-[60%] max-w-[75%]">
          <div className="animate-pulse">
            <ul className="space-y-3">
              <li className="w-[75%] h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
              <li className="w-[60%] h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
              <li className="w-[85%] h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
            </ul>
          </div>
        </div>
      </div>
      )
    }
    { state.chatBlocked && streamingData.length > 0 &&
      ( 
        <div className="flex items-start space-x-3">
          <img src="/bot.png" alt="User Avatar" className="w-10 h-10 rounded-full hidden md:block" />
          <div className="bg-gray-100 dark:bg-neutral-800 rounded-lg p-3 max-w-[75%]">
          <FormattedText content={streamingData} />
          </div>
        </div>    
      )
    }
    </div>
  );
}

export const Messages = ({ messageHistory }: MessagesProps) =>
{
    const { keycloak } = useAuth();
    const scrollRef = createRef<HTMLDivElement>();

    useEffect(() => 
    {
      if(scrollRef.current)
      {  
        scrollRef.current.scrollIntoView({ block: 'end' });   
        if(process.env.REACT_APP_LOGGING)
          console.log('scrolling down')
      }
      const blocks: NodeListOf<HTMLElement> = document.querySelectorAll('pre code');
      blocks.forEach((value: HTMLElement, key: number, parent: NodeListOf<Element>) => 
      {
        if(!value.getAttribute("data-highlighted"))
          hljs.highlightElement(value);
      });
    }, [messageHistory]);



    return ( <div className="flex-1 overflow-y-auto px-1 lg:px-4 2xl:px-60 space-y-4">
        { Object.entries(messageHistory).map(([key, value]) => {
            if(value.sender_id == keycloak.tokenParsed?.sub)
            {  
              return (
                <VisibilityChecker debounceMs={0} threshold={0.2} rootMargin="50px">
                  {(isVisible) => (
                    <div className="rounded-lg overflow-hidden my-4">
                      {isVisible && 
                        <div key={key} className="flex items-start space-x-3 justify-end mr-3 md:mr-0">
                          <div className="bg-gray-100 dark:bg-neutral-700 rounded-lg p-3 max-w-[90%] md:max-w-[75%]">
                            {value.content && (<FormattedText content={value.content} />)}
                            {value.attachments && value.attachments.map((entity, index) => (
                            <img key={index} className="object-scale-down max-h-96 rounded-lg mt-2" src={process.env.REACT_APP_BACKEND_URL + '/static/' + keycloak.token + '/' + entity.path} />))}
                          </div>
                          <img src="/usr.png" alt="User Avatar" className="w-10 h-10 rounded-full hidden md:block" />
                        </div> 
                      }
                    </div>
                  )}
                </VisibilityChecker>
              );
            }
            else
            {
              return (
              <div className="rounded-lg overflow-hidden my-4">
                <div className="flex items-start space-x-3">
                  <img src="/bot.png" alt="User Avatar" className="w-10 h-10 rounded-full hidden md:block" />
                  <div className="bg-gray-100 dark:bg-neutral-700 rounded-lg p-3 max-w-[90%] md:max-w-[75%]">
                    {value.content && (<FormattedText content={value.content} />)}
                  </div>
                </div>
              </div>

              //   <VisibilityChecker debounceMs={0} threshold={0.2} rootMargin="50px">
              //   {(isVisible) => (
              //     <div className="rounded-lg overflow-hidden my-4">
              //       {isVisible ? 
              //       <div className="flex items-start space-x-3">
              //       <img src="/bot.png" alt="User Avatar" className="w-10 h-10 rounded-full hidden md:block" />
              //       <div className="bg-gray-100 dark:bg-neutral-800 rounded-lg p-3 max-w-[75%]">
              //         {value.content && (<FormattedText content={value.content} />)}
              //       </div>
              //       </div>
              //       : <div className="flex items-start space-x-3 h-8"></div>}
              //     </div>
              //   )}
              // </VisibilityChecker>
       
              );
            }
          })}
    <LastMessage />
    <div ref={scrollRef}></div>
    </div>);

}