import axios from "axios";
import  useAuth from './AuthContext';
import { isBooleanObject } from "util/types";
import { workerData } from "worker_threads";

export interface Attachment
{
    attachment_id: string;
    path: string;
    size: number;
    type: string;
}

export interface ChatMessage
{
    [key: string]:
    {
      content: string;
      sender_id: string;
      sent_at: string;
      attachments: Attachment[];
    };
}

export interface WorkerData
{
    name: string;
    token: string;
    createdAt: string;
    lastSeen: string;
}

export interface ModelsData
{
    [modelType: string]: string[];
}

export interface JobResponse
{
    finished: number;
    data: string;
}

export interface ChatWithTitle
{
    chatID: string;
    title: string;
}

export async function getAllChats(token: string): Promise<ChatWithTitle[] | null>
{
    try 
    {
        const data = { kcToken: token };
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/getUserChats', data);
        if(process.env.REACT_APP_LOGGING)
            console.log('Received chats', response.data);
        return response.data;
    } 
    catch (error) 
    {
        console.error('Unable to get chats!', error);
        return null;
    }
}

export async function createNewChat(token: string): Promise<void>
{
    try 
    {
        const data = { kcToken: token };
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/createChat', data);
        if(process.env.REACT_APP_LOGGING)
            console.log('Created new chat', response.data);
    } 
    catch (error) 
    {
        console.error('Unable to create chat!', error);
    }
}

export async function getChatMessages(token: string, chatID: string): Promise<ChatMessage>
{
    try 
    {
        const data = { kcToken:  token, chatID: chatID };
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/getChatMessages', data);
        if(process.env.REACT_APP_LOGGING)
            console.log('Received chat history', response.data);
        if(response.data === null)
            return {};
        return response.data;
    } 
    catch (error) 
    {
        console.error('Unable to get chat history!', error);
        return {};
    }
}

export async function sendChatMessage(token: string, modelName: string, chatID: string, outgoingMessage: string, attachedImage: string): Promise<string>
{
    try 
    {
        const data = { kcToken: token, model: modelName, chatID: chatID, message: outgoingMessage, attachedImage: attachedImage
            //image: imageBase64 ? imageBase64.replace(/^data:\w+\/\w+;base64,/, '') : ''
        };
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL+'/sendMessage', data);
        if(process.env.REACT_APP_LOGGING)
            console.log('Chat message submitted successfully!', response.data);
        return response.data;
    } 
    catch (error) 
    {
        console.error('Error sending message!', error);
        return "";
    }
}

export async function getJobData(token: string, jobID: string): Promise<JobResponse>
{
    try 
    {
        const data = { kcToken: token, jobID: jobID };
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL+'/jobStatus', data);
        if(process.env.REACT_APP_LOGGING)
            console.log('Job finished: ', response.data);
        return response.data;
    } 
    catch (error) 
    {
        console.error('Error sending message!', error);
        return {finished: 0, data: ''};
    }
}

export async function deleteChat(token: string, chatID: string): Promise<void>
{
    try 
    {
        const data = { kcToken: token, chatID: chatID };
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/deleteChat', data);
        if(process.env.REACT_APP_LOGGING)
            console.log('Deleted chat', response.data);
    } 
    catch (error) 
    {
        console.error('Unable to delete chat!', error);
    }
}

export async function createNewWorker(token: string): Promise<string>
{
    try 
    {
        const data = { kcToken: token, workerName: "worker" };
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/createWorker', data);
        if(process.env.REACT_APP_LOGGING)
            console.log('Created new worker', response.data);
        return response.data;
    } 
    catch (error) 
    {
        console.error('Unable to create chat!', error);
        return "";
    }
}

export async function listWorkers(token: string): Promise<WorkerData[] | null>
{
    try 
    {
        const data = { kcToken: token };
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/getWorkers', data);
        if(process.env.REACT_APP_LOGGING)
            console.log('Workers ', response.data);
        const res: WorkerData[] = [];
        response.data.forEach((element: string[]) => {
            res.push({name: element[0], token: element[1], createdAt: element[2], lastSeen: element[3]})
        });
        return res
    } 
    catch (error) 
    {
        console.error('Unable to get workers!', error);
        return null;
    }
}

export async function listModels(token: string): Promise<ModelsData | null>
{
    try 
    {
        const data = { kcToken: token };
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/getModels', data);
        if(process.env.REACT_APP_LOGGING)
            console.log('Models: ', response.data);
        return response.data;
    } 
    catch (error) 
    {
        console.error('Unable to get models!', error);
        return null;
    }
}

export async function deleteMyWorkers(token: string): Promise<void>
{
    try 
    {
        const data = { kcToken: token };
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/resetWorkers', data);
        if(process.env.REACT_APP_LOGGING)
            console.log('Resetting workers ', response.data);
    } 
    catch (error) 
    {
        console.error('Unable to reset workers!', error);
        return;
    }
}

export async function probeToken(token: string): Promise<void>
{
    try 
    {
        const data = { kcToken: token };
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/probeToken', data);
        if(process.env.REACT_APP_LOGGING)
            console.log('Token valid ', response.data);
    } 
    catch (error) 
    {
      console.error('Error fetching data:', error);
    }    
}

export async function checkRunningJobs(token: string, chatID: string): Promise<string>
{
    try 
    {
        const data = { kcToken: token, chatID: chatID };
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/getChatJobs', data);
        if(process.env.REACT_APP_LOGGING)
            console.log('Running jobs: ', response.data);
        return response.data
    } 
    catch (error) 
    {
      console.error('Error fetching data:', error);
    }    
    return "";
}