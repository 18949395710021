import { useEffect, useState } from 'react';
import useAuth from './AuthContext';
import { createNewWorker, deleteMyWorkers, listWorkers, WorkerData } from './API';


export const Workers = ()  =>
{
    const { keycloak } = useAuth();
    const [workersList, setWorkersList] = useState<WorkerData[]>();
    const [newWorkerName, setNewWorkerName] = useState<string>();

    const getWorkers = async () => 
    {
        if(!keycloak.token)
            return;
        const workers = await listWorkers(keycloak.token);
        if(workers)
        {
            setWorkersList(workers);
        }
        else
        {
            setWorkersList([]);
        }
    }

    const createWorker = async () => 
    {
        if(!keycloak.token)
            return;
        const res = await createNewWorker(keycloak.token);
        if(res)
            getWorkers();
    }

    const deleteAllWorkers = async () => 
    {
        if(!keycloak.token)
            return;
        await deleteMyWorkers(keycloak.token);
        getWorkers();
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewWorkerName(e.target.value);
    };

    useEffect(() => 
    {
        (async function() {
            await getWorkers();
        })();
    }, []);
    

    return (
        <div>
        <div>
          <label htmlFor="hs-trailing-button-add-on" className="sr-only">Label</label>
          <div className="flex rounded-lg shadow-sm">
            <input placeholder="Enter a name for your new worker" onChange={handleInputChange} value={newWorkerName} type="text" id="hs-trailing-button-add-on" name="hs-trailing-button-add-on" className="py-3 px-4 block w-full border-gray-200 shadow-sm rounded-s-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" />
            <button onClick={(e)=>{createWorker();e.preventDefault();}} type="button" className="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-e-md border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
            Create
            </button>
          </div>
        </div>
        {/* <button onClick={(e)=>{createWorker();e.preventDefault();}} type="button" className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
            Create New Worker
        </button> */}
        <br />
        <button onClick={(e)=>{deleteAllWorkers();e.preventDefault();}} type="button" className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
            Delete All
        </button>
        <div className="py-3 flex items-center text-sm text-gray-800 before:flex-1 before:border-t before:border-gray-200 before:me-6 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-white dark:before:border-neutral-600 dark:after:border-neutral-600">Registered workers</div>

        <div className="flex flex-col">
  <div className="-m-1.5 overflow-x-auto">
    <div className="p-1.5 min-w-full inline-block align-middle">
      <div className="overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
          <thead>
            <tr>
              <th scope="col" className="px-5 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">Name</th>
              <th scope="col" className="px-5 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">Token</th>
              <th scope="col" className="px-5 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">Created</th>
              <th scope="col" className="px-5 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">Last Seen</th>
              <th scope="col" className="px-5 py-3 text-end text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">

            {workersList && workersList.length > 0 && workersList.map((entity, index) => (
                <tr key={index}>
                    <td className="px-5 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">{entity.name}</td>
                    <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">{entity.token}</td>
                    <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">{entity.createdAt}</td>
                    <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">{entity.lastSeen}</td>
                    <td className="px-5 py-4 whitespace-nowrap text-end text-sm font-medium">
                        <button type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:text-blue-400">Delete</button>
                    </td>
                </tr>
            ))}

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


        </div>

    );
}