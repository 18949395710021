import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from './AuthContext';
import axios from 'axios';
import { KeycloakProfile } from 'keycloak-js';

const Example: React.FC = () => 
{
    const { keycloak } = useAuth();
    const [userProfile, setUserProfile] = useState<KeycloakProfile>();

    useEffect(() => 
    {
        (async function() 
        {
          setUserProfile(await keycloak.loadUserProfile());  
        })();
    }, []);

    const probeToken = async() => 
    {
      try 
      {
        const token = await keycloak.updateToken(30);
        if(process.env.REACT_APP_LOGGING)
          console.log('token renew ' + token);
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL+'/probe', 
        {
          headers: {
            'kcToken': `${keycloak.token}`
          }
        });

        if(process.env.REACT_APP_LOGGING)
          console.log(response.data);
      } 
      catch (error) 
      {
        console.error('Error fetching data:', error);
      }    
    }
  

    return (
        <div className="space-y-3">
          <dl className="flex flex-col sm:flex-row gap-1">
            <dt className="min-w-40">
              <span className="block text-sm text-gray-500 dark:text-neutral-500">Authenticated:</span>
            </dt>
            <dd>
              <ul>
                <li className="me-1 after:content-[','] inline-flex items-center text-sm text-gray-800 dark:text-neutral-200">
                  {keycloak.authenticated ? 'true' : 'false'}
                </li>
              </ul>
            </dd>
          </dl>

          <dl className="flex flex-col sm:flex-row gap-1">
            <dt className="min-w-40">
              <span className="block text-sm text-gray-500 dark:text-neutral-500">Username:</span>
            </dt>
            <dd>
              <ul>
                <li className="me-1 after:content-[','] inline-flex items-center text-sm text-gray-800 dark:text-neutral-200">
                    {userProfile?.username}
                </li>
              </ul>
            </dd>
          </dl>

          <dl className="flex flex-col sm:flex-row gap-1">
            <dt className="min-w-40">
              <span className="block text-sm text-gray-500 dark:text-neutral-500">Name:</span>
            </dt>
            <dd>
              <ul>
                <li className="me-1 after:content-[','] inline-flex items-center text-sm text-gray-800 dark:text-neutral-200">
                    {userProfile?.firstName} {userProfile?.lastName}
                </li>
              </ul>
            </dd>
          </dl>

          <dl className="flex flex-col sm:flex-row gap-1">
            <dt className="min-w-40">
              <span className="block text-sm text-gray-500 dark:text-neutral-500">Token data:</span>
            </dt>
            <dd>
              <ul>
                <li className="me-1 after:content-[','] inline-flex items-center text-sm text-gray-800 dark:text-neutral-200">
                    {keycloak.tokenParsed && JSON.stringify(keycloak.tokenParsed)}
                </li>
              </ul>
            </dd>
          </dl>

          <button onClick={probeToken} className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">Sign in</button>

        
        </div>
            );
};

export default Example;