import { useEffect, useState } from 'react';
import useAuth from './AuthContext';
import { useNavigate } from 'react-router-dom';

export const Landing = ()  =>
{
    const { keycloak } = useAuth();
    const navigate = useNavigate();

    const redirectToLogin = async () => 
    {
        keycloak.login();
    }

    useEffect(() => 
    {
        (async function() {
            // if(keycloak.authenticated)
            //     navigate("/app")
        })();
    }, []);
    
    return (

        <div className="bg-gradient-to-b from-violet-600/10 via-transparent">
          <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-24 space-y-8">
            <div className="flex justify-center">
            <h1 className="block font-medium text-gray-200 text-4xl sm:text-5xl md:text-6xl lg:text-7xl">Beep AI</h1>
            </div>
        
            <div className="max-w-3xl text-center mx-auto">
              <h1 className="block font-medium text-gray-200 text-1xl sm:text-5xl md:text-1xl lg:text-1xl">
                 Free AI Resources for All!
              </h1>
            </div>
        
            <div className="max-w-3xl text-center mx-auto">
              <p className="text-lg text-white/70">Collaborative Computing: Computing power is shared, not sold!</p><br />
              <p className="text-lg text-white/70">Join our volunteer-computing network to enable free access to high-performance computing resources 
              and accelerate innovation in the field of artificial intelligence. Our project leverages the collective processing power of participant computers 
              to provide a scalable, shared infrastructure for running computationally intensive AI tasks. 
              By contributing their unused GPU and CPU cycles, volunteers help make advanced machine learning algorithms and models more accessible 
              to researchers, developers, and users worldwide.</p>
            </div>
        
            <div className="text-center">
              <a onClick={() => {navigate("/app")}} className="inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 shadow-lg shadow-transparent hover:shadow-blue-700/50 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:shadow-blue-700/50 py-3 px-6" href="#">
                Get started
                <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
              </a>
            </div>
          </div>
        </div>

    );
}