import React, { ComponentPropsWithoutRef, useCallback, useEffect, useRef, useState } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';

interface VisibilityCheckerProps {
    children: (isVisible: boolean) => React.ReactNode;
    threshold?: number;
    rootMargin?: string;
    debounceMs?: number;
  }
  
  export const VisibilityChecker: React.FC<VisibilityCheckerProps> = ({
    children,
    threshold = 0.1,
    rootMargin = '0px',
    debounceMs = 100,
  }) => {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout>();
    const lastVisibilityRef = useRef<boolean>(false);
  
    const observerCallback = useCallback((entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      const newVisibility = entry.isIntersecting;
  
      if (newVisibility === lastVisibilityRef.current) {
        return;
      }
  
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
  
      timeoutRef.current = setTimeout(() => {
        lastVisibilityRef.current = newVisibility;
        setIsVisible(newVisibility);
      }, debounceMs);
    }, [debounceMs]);
  
    useEffect(() => {
      const currentElement = elementRef.current;
      
      if (!currentElement) return;
  
      const observer = new IntersectionObserver(observerCallback, {
        threshold: [0, threshold, 1.0],
        rootMargin,
      });
  
      observer.observe(currentElement);
  
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        if (currentElement) {
          observer.unobserve(currentElement);
        }
      };
    }, [threshold, rootMargin, observerCallback]);
  
    return (
      <div 
        ref={elementRef} 
        className="visibility-checker"
        style={{ minHeight: '20px' }}
      >
        {children(isVisible)}
      </div>
    );
  };

interface MarkdownRendererProps {
  content: string;
  className?: string;
}

type CodeProps = ComponentPropsWithoutRef<'code'> & {
  inline?: boolean;
};

export const FormattedText: React.FC<MarkdownRendererProps> = ({ content, className }) => {
  const ReactMarkdownMemo = React.useMemo(() => {
    if(process.env.REACT_APP_LOGGING)
      console.log("ReactMarkdownMemo render"); //
    const components: Components = {
      code({ inline, className, children, ...props }: CodeProps) {
        const hasMultipleLines = (str: string): boolean => {
          return str.includes('\n');
        }
        if(hasMultipleLines(String(children)))
          return (<div className="code-block py-4"><code>{String(children).replace(/\n$/, '')}</code></div>);
        else
          return (<code className=' '>{String(children).replace(/\n$/, '')}</code>);
      },
      p({ children }) {
        return <p className="leading-relaxed">{children}</p>;
      },
      h1({ children }) {
        return <h1 className="text-3xl font-bold mb-4 leading-loose">{children}</h1>;
      },
      h2({ children }) {
        return <h2 className="text-2xl font-bold mb-3 leading-loose">{children}</h2>;
      },
      h3({ children }) {
        return <h3 className="text-xl font-bold mb-2 leading-loose">{children}</h3>;
      },
      a({ children, href }) {
        return (
          <a 
            href={href} 
            className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-200"
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        );
      },
      ul({ children }) {
        return <ul className="flex flex-col">{children}</ul>;
      },
      ol({ children }) {
        return <ol className="list-decimal list-inside text-gray-800 dark:text-white leading-loose">{children}</ol>;
      },
      img({ src }) {
          return <img className="object-scale-down max-h-96 rounded-lg py-4" src={src}></img>;
      },
    };
    const ComponentConstructor = () => (
      <ReactMarkdown
        children={content}
        components={components}
      />
    );
    return ComponentConstructor;
  }, [content]);
  


  return (
    <div className={`prose dark:prose-invert max-w-none text-gray-800 dark:text-neutral-200 ${className || ''}`}>
      <ReactMarkdownMemo />
    </div>
  );
};