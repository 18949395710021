import Keycloak from 'keycloak-js';

const keycloakConfig = {
  url: process.env.REACT_APP_KC_URL,
  realm: 'master',
  clientId: 'react-frontend'
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;