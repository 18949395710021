
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import useAuth from './AuthContext';
import MainChat from './Main';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './Keycloack';
import Example from './Example';
import { Landing } from './Landing';
import { AppProvider, statePopUpWindow } from './AppContext';

const ProtectedRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  const { isAuthenticated, initialized } = useAuth();
  useEffect(() => {
    if (initialized) {
      if (!keycloak.authenticated) {
        keycloak.login();
      }
    }
  }, [initialized]);

  if (!initialized) {
    return (<div></div>);
  }

  if (!keycloak.authenticated) {
    return  (<div></div>);
  }
  
  return element;
};

const App: React.FC = () => 
{
  
  useEffect(() => 
  {
    const html = document.querySelector('html');
    if(html)
    {
        html.classList.remove('light');
        html.classList.add('dark');
    }
  }, []);

  return (
    <ReactKeycloakProvider authClient={keycloak} >
      <AppProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Landing />} /> 
            <Route path="/app" element={<ProtectedRoute element={<MainChat />} />} />
            <Route path="/test" element={<ProtectedRoute element={<Example />} />} />
            {/* <Route path="/new" element={<ProtectedRoute element={<HelloPrompt />} />} />
            <Route path="/hello" element={<ProtectedRoute element={<MainChat />} />} />
            <Route path="*" element={<Navigate to="/login" />} /> */}
          </Routes>
        </Router>
      </AppProvider>
    </ReactKeycloakProvider>
  );
};

export default App;