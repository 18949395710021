import { useKeycloak } from '@react-keycloak/web';

const useAuth = () => {
  const { keycloak, initialized } = useKeycloak();

  const login = () => keycloak.login();
  const logout = () => keycloak.logout();
  const updateToken = (minValidity: number) => keycloak.updateToken(minValidity);
  const isAuthenticated = keycloak.authenticated;

  return {
    login,
    logout,
    updateToken,
    initialized,
    isAuthenticated,
    keycloak
  };
};

export default useAuth;