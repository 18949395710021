import React, { ReactNode } from 'react';

interface PopupProps {
  children: ReactNode;
  windowName: string;
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ children, windowName, onClose }) => {
  return (
    <div id="popup" className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-[80]">
        <div className="relative top-20 mx-auto p-1 lg:p-4 border w-full lg:w-2/4 shadow-lg rounded-md dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            <div className="mt-3 text-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">{windowName}</h3>
                <div className="mt-2 px-7 py-3">
                  {children}

                <br/>
                <button onClick={onClose}  id="closePopup" className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
                        Close
                </button>
                </div>

            </div>
        </div>
    </div>

  
    
  );
};

export default Popup;

