import { useEffect, useState } from 'react';
import useAuth from './AuthContext';
import { listModels, ModelsData } from './API';
import { Select } from './Select';
import { useAppContext } from './AppContext';

export const Settings = ()  =>
{
    const { keycloak } = useAuth();
    const { state, setTextModel, setVisionModel, setAvailableModels } = useAppContext();

    const redirectToAccount = async () => 
    {
        if(!keycloak.token)
            return;

        keycloak.accountManagement();
    }
    
    useEffect(() => 
    {
        (async function() 
        {

            if(!keycloak.token)
            return;

            const models = await listModels(keycloak.token);
            if(models)
            {
                setAvailableModels(models);
                if(models.text && models.text.length > 0)
                {
                const storedModel = localStorage.getItem("txt-mdl");
                if(storedModel && models.text.includes(storedModel))
                {
                    setTextModel(storedModel);
                    if(process.env.REACT_APP_LOGGING)
                    console.log("using model from storage", storedModel);
                }
                else
                {
                    setTextModel(models.text[0]);
                    if(process.env.REACT_APP_LOGGING)
                    console.log("set model to", models.text[0]);
                }
                }
                if(models.vision && models.vision.length > 0)
                {
                const storedModel = localStorage.getItem("vis-mdl");
                if(storedModel && models.vision.includes(storedModel))
                {
                    setVisionModel(storedModel);
                    if(process.env.REACT_APP_LOGGING)
                    console.log("using vision model from storage", storedModel);
                }
                else
                {
                    setVisionModel(models.vision[0]);
                    if(process.env.REACT_APP_LOGGING)
                    console.log("set vision model to", models.vision[0]);
                }
                }
            }
        
        })();
    }, []);

    return (
<div>
<button onClick={() => { redirectToAccount() }} type="button" className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
  Change account settings
</button>
<br /> <br />
{state.availableModels.text && state.availableModels.text.length > 0 && <Select options={state.availableModels.text} value={state.textModel} onChange={setTextModel} label='Text Model' />}
{(!state.availableModels.text || state.availableModels.text.length < 1) && <p className='items-center px-2 py-1 gap-x-2 h-24px text-base font-normal'>No text models currently available</p>}
<br />
{state.availableModels.vision && state.availableModels.vision.length > 0 && <Select options={state.availableModels.vision} value={state.visionModel} onChange={setVisionModel} label='Vision Model' />}
{(!state.availableModels.vision || state.availableModels.vision.length < 1) && <p className='items-center px-2 py-1 gap-x-2 h-24px text-base font-normal'>No vision models currently available</p>}

<br />
</div>

    );
}