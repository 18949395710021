import React, { createContext, useContext, useState, useCallback, useMemo, ReactNode } from 'react';
import { ChatMessage, ChatWithTitle, ModelsData } from './API';

export const enum statePopUpWindow
{
    Closed = 0,
    Settings,
    Workers
};

interface AppState {
  popUpState: statePopUpWindow | null;
  chatBlocked: boolean;
  textModel: string;
  visionModel: string;
  availableModels: ModelsData;
  activeChatHistory: ChatMessage;
  activeChatID: string;
  runningJobID: string;
  chatList: ChatWithTitle[];
}

interface AppContextType {
  state: AppState;
  setPopUpState: (popUpState: statePopUpWindow | null) => void;
  setChatBlocked: (chatBlocked: boolean) => void;
  setTextModel: (textModel: string) => void;
  setVisionModel: (visionModel: string) => void;
  setAvailableModels: (availableModels: ModelsData) => void;
  setActiveChatHistory: (activeChatHistory: ChatMessage) => void;
  setActiveChatID: (activeChatID: string) => void;
  setRunningJobID: (runningJobID: string) => void;
  setChatList: (chatList: ChatWithTitle[]) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<AppState>({
    popUpState: statePopUpWindow.Closed,
    chatBlocked: false,
    textModel: '',
    visionModel: '',
    availableModels: {},
    activeChatHistory: {},
    activeChatID: '',
    runningJobID: '',
    chatList: []
  });

  const setPopUpState = useCallback((popUpState: statePopUpWindow | null) => {
    setState(prevState => ({ ...prevState, popUpState }));
  }, []);

  const setTextModel = useCallback((textModel: string) => {
    localStorage.setItem("txt-mdl", textModel);
    setState(prevState => ({ ...prevState, textModel }));
  }, []);

  const setVisionModel = useCallback((visionModel: string) => {
    localStorage.setItem("vis-mdl", visionModel);
    setState(prevState => ({ ...prevState, visionModel }));
  }, []);

  const setChatBlocked = useCallback((chatBlocked: boolean) => {
    setState(prevState => ({ ...prevState, chatBlocked }));
  }, []);

  const setAvailableModels = useCallback((availableModels: ModelsData) => {
    setState(prevState => ({ ...prevState, availableModels }));
  }, []);

  const setActiveChatHistory = useCallback((activeChatHistory: ChatMessage) => {
    setState(prevState => ({ ...prevState, activeChatHistory }));
  }, []);

  const setActiveChatID = useCallback((activeChatID: string) => {
    setState(prevState => ({ ...prevState, activeChatID }));
  }, []);

  const setRunningJobID = useCallback((runningJobID: string) => {
    setState(prevState => ({ ...prevState, runningJobID }));
  }, []);

  const setChatList = useCallback((chatList: ChatWithTitle[]) => {
    setState(prevState => ({ ...prevState, chatList }));
  }, []);

  const value = useMemo(() => ({
    state,
    setPopUpState,
    setChatBlocked,
    setTextModel,
    setVisionModel,
    setAvailableModels,
    setActiveChatHistory,
    setActiveChatID,
    setRunningJobID,
    setChatList
  }), [state, setPopUpState, setChatBlocked, setTextModel, setVisionModel, setAvailableModels, setActiveChatHistory, setActiveChatID, setRunningJobID, setChatList]);

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};